<template>
  <b-row>
    <b-col cols="12">
      <b-overlay
        :show="$apollo.loading || mutationLoading"
        spinner-variant="primary"
      >
        <b-card title="Score Application Response">
          <validation-observer
            ref="infoRules"
            tag="form"
          >
            <b-row
              v-for="(section, index) in questions"
              :key="index"
            >
              <b-col><h4 class="p-1 mb-2 rounded bg-light">
                {{ index }}
              </h4></b-col>
              <b-col
                cols="12"
                class="px-2"
              >
                <b-row
                  v-for="(question, index) in section"
                  :key="index"
                >
                  <b-col>
                    <b-form-group
                      :label="`Q. ` + question.question"
                      :label-for="`question-${index}`"
                      label-class="h5"
                    >
                      <h6 class="font-italic">
                        A. {{ question.programs_applicationresponsetables[0].response }}
                      </h6>

                      <b-form-input
                        :id="`score-${index}`"
                        v-model="question.programs_applicationresponsetables[0].score"
                        type="number"
                        placeholder="Score"
                        required
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="px-2 d-flex align-items-end">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-2 mt-1 py-1"
                  @click="updateAnswer"
                >
                  <span>Update Scores</span>
                </b-button>
              </b-col></b-row>
          </validation-observer>
        </b-card></b-overlay>
    </b-col>
  </b-row>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import {
  BButton, BCol, BFormGroup, BFormInput, BFormFile, BRow, BFormCheckbox, BFormCheckboxGroup, BCard, BFormTextarea, BIconBoxArrowUpRight, BOverlay,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { email, required } from '@core/utils/validations/validations'
import gql from 'graphql-tag'
import FormBuilder from './FormBuilder.vue'
import ObjectiveForm from '../../../components/ObjectiveForm.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    BButton,
    BFormCheckbox,
    BFormCheckboxGroup,
    FormBuilder,
    ObjectiveForm,
    ToastificationContent,
    BCard,
    BFormTextarea,
    BIconBoxArrowUpRight,
    BOverlay,
  },
  data() {
    return {
      applicationData: '',
      mutationLoading: false,
    }
  },
  computed: {
    questions() {
      return this.groupByKey(this.applicationData, 'section')
    },
    scores() {
      const arr = []
      this.applicationData.map(r => arr.push(r.programs_applicationresponsetables[0]))
      // eslint-disable-next-line no-param-reassign,no-underscore-dangle
      arr.map(x => delete x.__typename)
      return arr
    },
  },
  methods: {
    groupByKey(array, key) {
      return Array.from(array).reduce((hash, obj) => {
        if (obj[key] === undefined) return hash
        return Object.assign(hash, { [obj[key]]: (hash[obj[key]] || []).concat(obj) })
      }, {})
    },
    updateAnswer() {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`mutation ($answers: [programs_applicationresponsetable_insert_input!]!) {
          insert_programs_applicationresponsetable(objects: $answers, on_conflict: {constraint: programs_applicationresponsetable_pkey, update_columns: score}) {
            affected_rows
          }
        }`,
        variables: {
          answers: this.scores,
        },
        update: (store, { data: { insert_programs_applicationresponsetable } }) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: insert_programs_applicationresponsetable.affected_rows ? 'Answers submitted successfully' : 'Failed to submit answers',
              icon: insert_programs_applicationresponsetable.affected_rows ? 'CheckIcon' : 'XIcon',
              variant: insert_programs_applicationresponsetable.affected_rows ? 'success' : 'warning',
            },
          })
          this.mutationLoading = false
        },
      })
    },
  },
  apollo: {
    applicationData: {
      query() {
        return gql`
        {
          programs_basicinfo(where: {id: {_eq: ${this.$route.params.pid}}}) {
            id,
            programs_applicationformstables(where: {id: {_eq: ${this.$route.params.apid}}}) {
              round_number
              title
              programs_applicationsquestionstables {
                id
                answer_type
                question
                section
                programs_applicationresponsetables(where: {applicant_id: {_eq: ${this.$route.params.aid}}}) {
                  id
                  response
                  applicant_id
                  score
                }
              }
            }
          }
        }`
      },
      update: data => data.programs_basicinfo[0]?.programs_applicationformstables[0]?.programs_applicationsquestionstables,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
