<template>
  <div>
    <b-row
      v-for="(objective, index) in objectives"
      :key="index"
      class="ml-25 border-left"
    >
      <b-col>
        <b-form-group
          :label="`Objective ${index+1}`"
          :label-for="'objective-'+Math.random()+objective.id"
        >
          <b-form-input
            :id="'objective-'+Math.random()+objective.id"
            v-model="objective.title"
            placeholder="Objective"
            required
            type="text"
            @change="notifyParent()"
          />
        </b-form-group>
      </b-col>
      <b-col class="col-auto d-flex align-items-end pb-1">
        <b-button
          v-if="index === objectives.length - 1"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          :disabled="!objective.title"
          variant="primary"
          class="mr-2 mt-2"
          @click="objectives.push(
            {
              id: Math.random(),
              title: '',
              evaluations: [
                {
                  id: 1,
                  question: '',
                  dataType: '',
                },
              ],
            })"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-25"
          />
          <span>Add Objective</span>
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="danger"
          @click="objectives.splice(index, 1)"
        >
          <feather-icon
            class="mr-25"
            icon="XIcon"
          />
          <span>Remove</span>
        </b-button>
      </b-col>
      <b-col
        md="12"
      >
        <b-row
          v-for="(evaluation, index) in objective.evaluations"
          :id="evaluation.id"
          :key="evaluation.id"
          class="border-left ml-25"
        >
          <!-- Item Name -->
          <b-col md="6">
            <b-form-group
              :label="`Question ${index+1}`"
              :label-for="'question-'+evaluation.id"
            >
              <b-form-input
                :id="'question-'+evaluation.id"
                v-model="evaluation.question"
                placeholder="Question"
                required
                type="text"
                @change="notifyParent()"
              />
            </b-form-group>
          </b-col>

          <!-- Answer -->
          <b-col>
            <b-form-group
              :label-for="'dataType-'+evaluation.id"
              label="Data Type"
            >
              <v-select
                id="'dataType-'+Math.random()"
                v-model="evaluation.dataType"
                :options="['Text',
                           'Number',
                           'Email',
                           'Website URL',
                           'Upload File',]"
                append-to-body
                label="Data type"
                placeholder="Select from list"
                @change="notifyParent()"
              />
            </b-form-group>
          </b-col>
          <b-col class="col-auto d-flex align-items-end pb-1">
            <b-button
              v-if="index === objective.evaluations.length - 1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :disabled="!evaluation.question || !evaluation.dataType"
              variant="outline-primary"
              class="mr-2"
              @click="objective.evaluations.push({
                id: Math.random(),
                question: '',
                dataType: '',
              })"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-25"
              />
              <span>Add Question</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-danger"
              @click="objective.evaluations.splice(index, 1)"
            >
              <feather-icon
                class="mr-25"
                icon="XIcon"
              />
              <span>Remove</span>
            </b-button>
          </b-col>
        </b-row>

      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BButton, BCol, BFormGroup, BFormInput, BRow,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@core/utils/validations/validations'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
  },
  data() {
    return {
      objectives: [
        {
          id: 1,
          title: '',
          evaluations: [
            {
              id: 1,
              question: '',
              dataType: '',
            },
          ],
        },
      ],
      required,
    }
  },
  methods: {
    notifyParent() {
      this.$emit('input', this.objectives)
    },
  },
}
</script>
